import React from "react";
import Layout from "../components/Layout";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="container">
          <h1>404</h1>
          <p>Η σελίδα που ψάχνετε δεν υπάρχει...</p>
          <a href="/">Πίσω στην κεντρική σελίδα</a>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
